<template>
  <div id="app">
    <div class="parallax">
      <div class="parallax-content"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener('scroll', this.parallaxEffect);
  },
  destroyed() {
    window.removeEventListener('scroll', this.parallaxEffect);
  },
  methods: {
    parallaxEffect() {
      const parallaxContent = document.querySelector('.parallax-content');
      const scrolled = window.pageYOffset;
      parallaxContent.style.transform = `translateY(-${scrolled}px)`;
    },
  },
};
</script>

<style lang="scss">
#app {
  background-image: url(@/assets/image/COMP_EDITED.png);
  height: 200vh;
  background-position: right;
  background-repeat: no-repeat;
  position: relative;
  background-color: dimgray;
}

.parallax {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  background: dimgray;
  filter: blur(0);
  -webkit-filter: blur(0);
  -webkit-mask-box-image: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, #ffffff 20%, #ffffff 80%, rgb(204 24 24 / 0%) 100%);

}

.parallax-content {
  position: fixed;
  top: -12.5%;
  left: 0;
  width: 100%;
  height: 200vh;
  background-image: url(@/assets/image/REAL.png);
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
